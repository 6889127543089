import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { GlobalService } from 'src/app/services/global-service/global.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import { showSaibaMais } from 'src/app/state/state.actions';

@Component({
  selector: 'cls-saiba-mais',
  templateUrl: './saiba-mais.component.html',
  styleUrls: ['./saiba-mais.component.scss']
})

export class SaibaMaisComponent implements OnInit, OnDestroy {

  @Input() project;
  selectedFase: any;
  state: any;
  todosObjetivosAprendizagem = [];
  stateSubscription$: any;

  constructor(private globalService: GlobalService, private store: Store<AppState>) { }

  ngOnDestroy(): void {
    this.stateSubscription$.unsubscribe()
  }

  ngOnInit() {
    this.getState();
    if (this.state.fases) {
      this.selectedFase = this.state.fases[0];
      this.globalService.vimeo();
      this.getTodosObjetivosAprendizagem();
    }
  }

  ngOnChanges() {
    setTimeout(() => {
      this.globalService.vimeo();
    }, 1000);
  }

  getState() {
    this.stateSubscription$ = this.store.select((state: any) => {
      this.state = state.newAppState;
    }).subscribe();
  }

  getTodosObjetivosAprendizagem() {
    this.todosObjetivosAprendizagem = [];

    this.state.fases.forEach(fase => {
      fase.objetivosAprendizagem.forEach(objetivo => {
        this.todosObjetivosAprendizagem.push(objetivo);
      });
    });
    this.todosObjetivosAprendizagem = [...new Map(this.todosObjetivosAprendizagem.map(item => [item['id_objetivo_aprendizagem'], item])).values()]
  }

  generateSambaVideoFrame(ancora: string) { // usar embed link
    const iframe = `<iframe allowfullscreen webkitallowfullscreen mozallowfullscreen width="640" height="360" src="${ancora}" scrolling="no" frameborder="0" allow="geolocation; microphone; camera; encrypted-media; midi">`
    return iframe
  }

  generateYoutubeFrame(ancora: string) { // usar embed link
    const youtubeVideoId = this.getYoutubeVideoIdFromUrl(ancora)
    const iframe = `<iframe id='ytplayer' type='text/html' width='663' height='372' src="https://www.youtube.com/embed/${youtubeVideoId}" frameborder='0'></iframe>`
    return iframe
  }

  getYoutubeVideoIdFromUrl(url: string): string {
    const YOUTUBE_VIDEO_ID_LENGTH = 11
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length >= YOUTUBE_VIDEO_ID_LENGTH)
      return match[2];
    else {
      let videoUrl = url
      if (videoUrl.includes("https://")) videoUrl = videoUrl.slice(8)
      const index = videoUrl.indexOf("/")
      return index !== -1 ? videoUrl.slice(videoUrl.indexOf("/") + 1) : 'error'
    }
  }

  videoSource(ancora) {
    let ret = null;
    if (!ancora) return ret
    switch (true) {
      case ancora.indexOf('vimeo.com') !== -1:
        ret = 'vimeo'
        break
      case ancora.indexOf('youtu') !== -1:
        ret = 'youtube'
        break
      case ancora.indexOf('liquidplatform.com') !== -1:
        ret = 'sambavideo'
        break
      default:
        break
    }
    return ret;
  }

  activeFase(fase) {
    this.selectedFase = fase;
  }

  closeSaibaMais() {
    this.store.dispatch(showSaibaMais({ payload: false }))
    document.querySelector('body').removeAttribute('class');
  }

  handleClickOutside(event) {
    setTimeout(() => {
      this.closeSaibaMais();
    }, 300);
  }

}
