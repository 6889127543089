<div
  CloseOnClickOutside
  (clickOutside)="handleClickOutside($event)"
  class="saiba-mais-container"
  [class.background-corp]="state?.projetoAtivo?.educacao_corporativa"
  [class.background-sup]="!state?.projetoAtivo?.educacao_corporativa"
>
  <div class="main-header">
    <div class="upper">
      {{ "SAIBA_MAIS.PROJECT" | translate }}
      <span>{{ "SAIBA_MAIS.PROJECT1" | translate }}</span>
    </div>
    <div class="close-pattern" (click)="closeSaibaMais()">
      <!-- <i class="fas fa-times pointer"></i> -->
      <img src="assets/icons/fas-fa-times-pointer-white.svg" />
    </div>
  </div>
  <div class="corpo">
    <div
      class="video-border youtube"
      *ngIf="videoSource(state?.projetoAtivo?.ancora) === 'youtube'"
      [innerHtml]="
        generateYoutubeFrame(state?.projetoAtivo?.ancora) | sanitizeHtml
      "
    ></div>
    <div
      class="video-border video"
      *ngIf="videoSource(state?.projetoAtivo?.ancora) === 'vimeo'"
    >
      <div
        name="vimeo"
        class="vimeo"
        [id]="state?.projetoAtivo?.ancora"
        data-vimeo-responsive="true"
      ></div>
    </div>
    <div
      class="video-border sambavideo"
      *ngIf="videoSource(state?.projetoAtivo?.ancora) === 'sambavideo'"
      [innerHtml]="
        generateSambaVideoFrame(state?.projetoAtivo?.ancora) | sanitizeHtml
      "
    ></div>
    <div
      class="text"
      [innerHtml]="state?.projetoAtivo?.descricao | sanitizeHtml"
    ></div>
    <div *ngIf="state?.projetoAtivo?.questao_motriz" class="title text">
      {{ "SAIBA_MAIS.MOTRIZ" | translate }}
    </div>
    <div class="text">{{ state?.projetoAtivo?.questao_motriz }}</div>
    <div class="resumo" *ngIf="state?.fases">
      <ul class="nav nav-tabs" role="tablist">
        <li
          class="nav-item"
          [class.active]="fase.id_fase_turma == selectedFase?.id_fase_turma"
          [style.z-index]="state?.fases.length - i"
          *ngFor="let fase of state?.fases; let i = index"
          (click)="activeFase(fase)"
        >
          <a
            class="nav-link"
            id="home-tab"
            data-toggle="tab"
            role="tab"
            aria-selected="true"
            >{{ fase.nome }}</a
          >
        </li>
      </ul>
      <div class="tab-content">
        <div
          class="tab-pane fade"
          [ngClass]="
            fase.id_fase_turma == selectedFase?.id_fase_turma
              ? 'active show'
              : ''
          "
          *ngFor="let fase of state?.fases; let i = index"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          <div class="delivery-date">
            Entrega
            {{ fase?.entregaTurma[0]?.data_entrega | date : "dd/MM/yyyy" }}
          </div>
          <div class="blr"></div>
          <div *ngIf="state?.fases?.length != i + 1">
            <div class="content-box">
              <div class="header capitalize">
                {{ "SAIBA_MAIS.LEARN" | translate }}
              </div>
              <ul>
                <li *ngFor="let objetivo of fase.objetivosAprendizagem">
                  {{ objetivo.nome }}
                </li>
              </ul>
            </div>
            <div class="divisor"></div>
            <div class="content-box">
              <div class="header capitalize">
                {{ "SAIBA_MAIS.DELIVER" | translate }}
              </div>
              <ul>
                <li
                  *ngFor="
                    let entrega of fase.entregaTurma | orderBy : 'ordenacao'
                  "
                >
                  {{ entrega.item_entrega_turma }}
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="state?.fases?.length == i + 1">
            <div class="content-box">
              <div class="header capitalize">
                {{ "SAIBA_MAIS.LEARN1" | translate }}
              </div>
              <ul>
                <li *ngFor="let objetivo of todosObjetivosAprendizagem">
                  {{ objetivo.nome }}
                </li>
              </ul>
            </div>
            <div class="divisor"></div>
            <div class="content-box">
              <div class="header capitalize">
                {{ "SAIBA_MAIS.DELIVER" | translate }}
              </div>
              <ul>
                <li
                  *ngFor="
                    let entrega of fase.entregaTurma | orderBy : 'ordenacao'
                  "
                >
                  {{ entrega.item_entrega_turma }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
